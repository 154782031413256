<template>
  <section class="team">
    <div class="container">
      <div class="wrapper">
        <TextBlock
          class="text-block"
          :key="index"
          v-for="(textBlock, index) in textBlocks"
          :text-block="textBlock"
        />

        <div class="leadership">
          <div class="title">Leadership</div>
        </div>
      </div>
      <div class="leaders">
        <div :key="index" v-for="(leader, index) in leaders" class="leader">
          <img :src="leader.img" :alt="leader.name" class="photo" />
          <a :href="leader.link" class="name">{{ leader.name }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextBlock from "@/components/TextBlock";
export default {
  name: "Home",
  components: { TextBlock },
  data() {
    return {
      leaders: [
        {
          img: "/img/steve.jpeg",
          name: "Steve Cashman",
          link: "https://www.linkedin.com/in/stcashman/",
        },
        {
          img: "/img/brian.jpeg",
          name: "Brian Russon",
          link: "https://www.linkedin.com/in/brian-russon-0a26224/",
        },
        {
          img: "/img/alex.jpeg",
          name: "Alex Zoller",
          link: "https://www.linkedin.com/in/alexzoller/",
        },
      ],
      textBlocks: [
        {
          title: "ABOUT US",
          text: `We’re a group of creative thinkers and doers who have 
          built companies that transformed healthcare.`,
          isShowDivider: true,
        },
        {
          title: "OUR APPROACH",
          text: `Our unique approach to the market is based on a combined 50 years of leadership experience in 
          technology and healthcare. Over this time, we have worked for Fortune 100 companies, 
          multiple startups, pre-IPO and post-IPO organizations, and have had successful exits in multiple investments.`,
          isShowDivider: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  background-clip: border-box;
}

.wrapper {
  margin: 80px 180px;
  padding-right: 0px;
  padding-left: 0px;

  @media screen and (max-width: 767px) {
    margin: 80px 80px;
  }
}

.text-block:not(:last-child) {
  margin-bottom: 50px;
}

.leadership {
  .title {
    color: #1a1b1f;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 62px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.leaders {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 100px;
}

.leader {
  margin-bottom: 25px;
  .photo {
    width: 331px;
    margin-bottom: 25px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }

  .name {
    display: block;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    color: #1a1b1f;
    text-decoration: underline;
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
  }
}
</style>
